* {
  margin: 0;
  padding: 0;
  
}
body {
  font: var(--vamtam-primary-font-font-weight)
    var(--vamtam-primary-font-font-style) var(--vamtam-primary-font-font-size) /
    var(--vamtam-primary-font-line-height)
    var(--vamtam-primary-font-font-family);

  /*background: rgb(45, 70, 51);
  background: linear-gradient(
    90deg,
    rgba(45, 70, 51, 1) 50%,
    rgba(49, 94, 66, 1) 100%
  );*/
  background-color: rgb(245, 245, 245);
}
.navStyle {
  font: var(--vamtam-primary-font-font-weight)
    var(--vamtam-primary-font-font-style) var(--vamtam-primary-font-font-size) /
    var(--vamtam-primary-font-line-height)
    var(--vamtam-primary-font-font-family);
}

.list-item::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 4px;
  opacity: 0;
  background: linear-gradient(to right, #019003, #006b4d);
  transition: all 0.8s;
}
.list-item::after {
  content: "";
  position: absolute;
  top: -4px;
  right: 0;
  width: 0;
  height: 4px;
  opacity: 0;
  background: linear-gradient(to left, #019003, #006b4d);
  transition: all 0.8s;
}

.list-item:hover::before {
  width: 100%;
  opacity: 1;
}
.list-item:hover::after {
  width: 100%;
  opacity: 1;
}

.swiper {
  width: 1200px;
  height: 680px;
}
.swiper-button-next,
.swiper-button-prev {
  color: #00d419 !important;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #ffffff !important;
}
.swiper-pagination-bullet {
  background-color: #646464 !important;
  opacity: 2 !important;
}
.swiper-pagination-bullet-active {
  background-color: #00d419 !important;
  border: 1px solid white;
}
@media (max-width: 1220px) {
  .swiper {
    width: 850px;
    height: 440px;
  }
}
@media (max-width: 860px) {
  .swiper {
    width: 600px;
    height: 320px;
  }
}
@media (max-width: 610px) {
  .swiper {
    width: 370px;
    height: 200px;
  }
}
@media (max-width: 370px) {
  .swiper {
    width: 270px;
    height: 180px;
  }
}

/* breakpoint cards écrans de taille moyenne (768-1119px) */
@media (min-width: 768px) and (max-width: 1119px) {
  .grid-cols-medium {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* breakpoint cards Pour les écrans plus larges (1120px et plus) */
@media (min-width: 1120px) {
  .grid-cols-large {
    grid-template-columns: repeat(3, 1fr);
  }
}
.title-pages {
  /*background: linear-gradient(
      to left,
      rgba(99, 99, 99, 0.779),
      rgba(99, 99, 99, 0.46),
      rgba(99, 99, 99, 0.779)
    ),
    linear-gradient(
      to right,
      rgba(99, 99, 99, 0.779),
      rgba(99, 99, 99, 0.46),
      rgba(99, 99, 99, 0.779)
    );*/
}
.animate-slide-in-left {
  animation-name: slide-in-left;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-in-right {
  animation-name: slide-in-right;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes slide-in-right {
  from {
    opacity: 0;
    transform: translateX(+100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.animate-slide-in-bottom {
  animation-name: slide-in-bottom;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes slide-in-bottom {
  from {
    opacity: 0;
    transform: translateY(+100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
